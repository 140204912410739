import { Box, Chip } from '@mui/material';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import ConfirmModal from '../../../components/ConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useMemo, useState } from 'react';
import DomainToolbar from '../Toolbar';
import useDomain from '../useDomain';

type Domain = {
  domainName: string;
  domainLink: string;
  active: boolean;
  _id: string;
  createdAt: string;
  updateAt: string;
};

function DomainTable() {
  const [domains, setDomains] = useState<Domain[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<GridRowId | null>(null);

  const { getDomains, deleteDomain } = useDomain();

  const init = () => {
    setLoading(true);
    const instance = getDomains();

    (async function () {
      try {
        const domains = await instance.call();
        setDomains(domains);
      } catch (error) {
        console.error('Error fetching domains:', error);
      } finally {
        setLoading(false);
      }
    })();

    return instance.abort;
  };

  const onAddDomainDone = (newDomain: Domain) => {
    setDomains((prevDomains) => [...prevDomains, newDomain]);
  };

  const onEditDomainDone = (updatedDomain: Domain) => {
    setDomains((prevDomains) =>
      prevDomains.map((domain) =>
        domain._id === updatedDomain._id ? updatedDomain : domain
      )
    );
  };

  const onDelete = async (id: string) => {
    try {
      setLoading(true);
      await deleteDomain().call(id);
      setDomains((prevDomains) =>
        prevDomains.filter((domain) => domain._id !== id)
      );
    } catch (error) {
      console.error('Error deleting domain:', error);
    } finally {
      setLoading(false);
    }
  };

  const tableCols: GridColDef[] = [
    {
      headerName: 'Name',
      field: 'domainName',
      flex: 1,
    },
    {
      headerName: 'Link to domain',
      field: 'domainLink',
      flex: 2,
    },
    {
      headerName: 'Is entry',
      field: 'isEntry',
      flex: 1,
      renderCell: (params) => {
        const entry = params.row?.isEntry || false;
        return <p>{entry ? 'Yes' : 'No'}</p>;
      },
    },
    {
      headerName: 'Active',
      field: 'active',
      flex: 1,
      renderCell: (params) => {
        const chipLabel = params.row.active ? 'Active' : 'Inactive';
        const chipColor = params.row.active ? 'primary' : 'default';

        return <Chip label={chipLabel} color={chipColor} />;
      },
    },
    {
      headerName: 'Actions',
      field: '_id',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box>
          <ConfirmModal
            onConfirm={() => onDelete(params.row._id)}
            title="Delete"
            icon={<DeleteIcon />}
          >
            Do you want to delete this domain?
          </ConfirmModal>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    return init();
  }, []);

  const selectedRowData = useMemo(() => {
    if (!selectedRow) return null;
    return domains.find((domain) => domain._id === selectedRow) || null;
  }, [domains, selectedRow]);

  return (
    <DataGrid
      autoHeight
      rows={domains}
      columns={tableCols}
      getRowId={(r) => r._id}
      rowSelectionModel={selectedRow ? [selectedRow] : []}
      onRowClick={(params) => {
        setSelectedRow((prev) => (prev === params.id ? null : params.id));
      }}
      loading={loading}
      slots={{
        toolbar: () => (
          <DomainToolbar
            selectedRowData={selectedRowData}
            onAddDomainDone={onAddDomainDone}
            onEditDomainDone={onEditDomainDone}
          />
        ),
      }}
    />
  );
}

export default DomainTable;
