import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import SimpleToolbar from '../../../../components/SimpleToolbar';
import AddPostback from '../../AddPostback';
import EditPostback from '../../EditPostback';

import usePostback from '../../usePostback';
import { useState } from 'react';
import { showMessage } from '../../../../components/utils/showMessage';
import DynamicToolbar from '../../../../components/DynamicToolbar';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import DeletePostback from '../../DeletePostback';
import ImportOfferDialog from '../../UploadCsvPostback';
import { Button } from '@mui/material';
import { on } from 'events';

export default function PostbackToolbar({
  selectedRow,
  onAddPostbackDone,
  onEditPostbackDone,
  postbacks,
  setPostbacks
}) {
  const { addPostback, editPostback, deletePostback, selectedFiring } = usePostback();
  const [loading, setLoading] = useState(false);

  const onAddPostback = async (newPostback) => {
    setLoading(true);
    try {
      const postback = await addPostback().call(newPostback);
      onAddPostbackDone(postback);
      showMessage({ message: 'Postback added successfully!' });
    } catch (error) {
      console.error('Error adding postback:', error);
      showMessage({
        message: error.message || 'Failed to add postback.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const onEditPostback = async (newPostback) => {
    setLoading(true);
    try {
      const postback = await editPostback().call(selectedRow._id, newPostback);
      onEditPostbackDone(postback);
      showMessage({ message: 'Postback updated successfully!' });
    } catch (error) {
      console.error('Error editing postback:', error);
      showMessage({
        message: error.message || 'Failed to update postback.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const onDeletePostback = async (selectedRow) => {
    try {
      setLoading(true);
      await deletePostback().call(selectedRow._id);
      const newPostbacks = postbacks.filter((item) => item._id !== selectedRow._id);
      setPostbacks(newPostbacks);
      showMessage({message: 'Postback deleted successfully'});
    } catch (error) {
      console.error('Error editing postback:', error);
      showMessage({message: 'Failed to delete postback', severity: 'error'});
    } finally {
      setLoading(false);
    }
  };

  const onImportCsv = async (data, isCsv, fileName) => {
    if (!isCsv) {
      showMessage({ message: 'Only CSV files are allowed.', severity: 'error' });
      return;
    }
  
    try {
      setLoading(true);
      selectedFiring().call(data)
      showMessage({ message: `CSV data is being proccessed please check logs later! ${fileName}` });
    } catch (error) {
      console.error('Error processing CSV data:', error);
      showMessage({ message: 'Failed to process CSV data.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };
  
  const modalButtons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open, handleClose) => {
        return (
          <AddPostback
            open={open}
            handleClose={handleClose}
            onSubmit={onAddPostback}
            loading={loading}
          />
        );
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      renderIf: Boolean(selectedRow),
      icon: <EditIcon />,
      render: (open, handleClose) => {
        return (
          <EditPostback
            open={open}
            handleClose={handleClose}
            initialData={selectedRow}
            onSubmit={onEditPostback}
            loading={loading}
          />
        );
      },
    },
    {
      name: 'delete',
      label: 'Delete',
      renderIf: Boolean(selectedRow),
      icon: <DeleteIcon />,
      render: (open, handleClose) => {
        return (
          <DeletePostback
            initialData={selectedRow}
            open={open}
            handleClose={handleClose}
            onSubmit={onDeletePostback}
            loading={loading}
          />
        );
      },
    },
    {
      name: 'uploadCsv',
      label: 'Upload CSV',
      renderIf: true,
      icon: <UploadIcon />,
      render: (open, handleClose) => {
        return (
          <ImportOfferDialog
          open={open}
          handleClose={handleClose}
          onUpload={onImportCsv}
          postbacks={postbacks}
        />
        );
      },
    },
  ];

  return <DynamicToolbar 
  modalButtons={modalButtons} 
  />;
}
