import React from 'react';
import {
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  Tooltip,
  IconButton,
  ButtonGroup,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface GeneralSettingsProps {
  size: string;
  data: {
    name: string;
    trafficChannel: string;
    domain: string;
    costType: string;
    value: string;
  };
  onChange: (field: string, value: any) => void;
}

const GeneralSettings: React.FC<GeneralSettingsProps> = ({
  size = 'small',
  data,
  onChange,
}) => {
  const costTypes = ['CPC', 'CPA', 'CPM', 'POPCPM', 'REVSHARE', 'DONOTTRACK'];

  return (
    <Box px={2} borderColor="grey.300" borderRadius={2} mt={2}>
      <Grid container spacing={2}>
        {/* Campaign Name */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name *"
            value={data.name}
            onChange={(e) => onChange('name', e.target.value)}
            variant="outlined"
            size="small"
          />
        </Grid>

        {/* Traffic Channel and Domain */}
        {/* <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            value={data.trafficChannel}
            onChange={(e) => onChange('trafficChannel', e.target.value)}
            displayEmpty
            variant="outlined"
            size="small"
          >
            <MenuItem value="" disabled>
              Traffic channel
            </MenuItem>
            <MenuItem value="channel1">Channel 1</MenuItem>
            <MenuItem value="channel2">Channel 2</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            value={data.domain}
            onChange={(e) => onChange('domain', e.target.value)}
            displayEmpty
            variant="outlined"
            size="small"
          >
            <MenuItem value="" disabled>
              Domain
            </MenuItem>
            <MenuItem value="domain1">Domain 1</MenuItem>
            <MenuItem value="domain2">Domain 2</MenuItem>
          </Select>
        </Grid> */}

        {/* Campaign Cost */}
        {/* <Grid item xs={12}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body2" color="textSecondary">
              Campaign Cost
            </Typography>
            <Tooltip title="Choose a cost type for the campaign">
              <IconButton size="small">
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" gap={1} mt={1}>
            <ButtonGroup variant="outlined" size={'small'} fullWidth>
              {costTypes.map((type) => (
                <Button
                  key={type}
                  variant={data.costType === type ? 'contained' : 'outlined'}
                  onClick={() => onChange('costType', type)}
                  size="small"
                >
                  {type}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </Grid> */}

        {/* Value */}
        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            label="Value *"
            value={data.value}
            onChange={(e) => onChange('value', e.target.value)}
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <Typography variant="body2">$</Typography>,
            }}
          />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default GeneralSettings;
