import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Autocomplete,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import useOffer from '../../offer/useOffer';

interface Offer {
  id: number;
  label: string;
  weight: number;
  offerId: string | null;
}

interface OffersProps {
  offers: Offer[];
  onChange: (updatedOffers: Offer[]) => void;
}

const Offers: React.FC<OffersProps> = ({ offers, onChange }) => {
  const { getOffers } = useOffer();
  const [loading, setLoading] = useState(false);
  const [offersOptions, setOffersOptions] = useState<any[]>([]);

  const init = () => {
    setLoading(true);
    const apiCall = getOffers();

    (async function () {
      try {
        const res = await apiCall.call();
        setOffersOptions(res);
      } catch (e) {
        console.error('Error fetching offers:', e);
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort ? apiCall.abort : () => {};
  };

  useEffect(() => {
    return init();
  }, []);

  const handleOfferChange = (
    id: number,
    field: string,
    value: any,
    offerId: string | null
  ) => {
    const updatedOffers = offers.map((offer) =>
      offer.id === id
        ? { ...offer, [field]: value, offerId } // Store offerId in the selected offer
        : offer
    );
    onChange(updatedOffers);
  };

  const handleAddOffer = () => {
    const newOffer: Offer = {
      id: offers.length + 1, // Generate a unique ID
      label: '',
      weight: 100,
      offerId: null, // Initialize with null
    };
    onChange([...offers, newOffer]);
  };

  const handleRemoveOffer = (id: number) => {
    const updatedOffers = offers.filter((offer) => offer.id !== id);
    onChange(updatedOffers);
  };

  return (
    <Box>
      {offers.map((offer, index) => (
        <Box
          key={offer.id}
          display="flex"
          alignItems="center"
          gap={2}
          my={2}
          px={2}
          // border={1}
          borderColor="grey.300"
          borderRadius={1}
        >
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Autocomplete
                fullWidth
                size="small"
                options={offersOptions.filter(
                  (option) =>
                    !offers.some((o) => o.offerId === option._id) ||
                    offer.offerId === option._id
                )} // Prevent duplicates by filtering based on _id
                getOptionLabel={(option) => option.name || ''} // Display the offer's name
                value={
                  offersOptions.find((opt) => opt._id === offer.offerId) || null
                } // Match offer _id with selected option's _id
                onChange={(_, newValue) =>
                  handleOfferChange(
                    offer.id,
                    'label',
                    newValue ? newValue.name : '', // Update label with the selected option name
                    newValue ? newValue._id : null // Store the _id of the selected option
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`Offer #${index + 1}`}
                    variant="outlined"
                  />
                )}
                loading={loading}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => handleRemoveOffer(offer.id)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ))}
      <Button
        variant="contained"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleAddOffer}
      >
        Add Offer
      </Button>
    </Box>
  );
};

export default Offers;
