import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  TextField,
  MenuItem,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import LoadingButton from '../../../components/LoadingButton';
import { showMessage } from '../../../components/utils/showMessage';

type Props = {
  open: boolean;
  handleClose: () => void;
  onSubmit: (newValues: Domain) => Promise<any>;
};

type Domain = {
  domainName: string;
  domainLink: string;
  isEntry: boolean;
};

function AddDomain({ open, handleClose, onSubmit }: Props) {
  const [values, setValues] = useState({
    domainName: '',
    domainLink: '',
    isEntry: false,
  });
  const [loading, setLoading] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((old) => ({
      ...old,
      [name]: name === 'isEntry' ? Boolean(Number(value)) : value,
    }));
  };

  const handleCancel = () => {
    setValues({ domainName: '', domainLink: '', isEntry: false });
    handleClose();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await onSubmit(values);
      setValues({ domainName: '', domainLink: '', isEntry: false });
      showMessage({
        message: 'Domain created successfully!',
        severity: 'success',
      });
      handleClose();
    } catch (error: any) {
      console.error('Error creating domain:', error);
      showMessage({
        message: error.message || 'Failed to create domain.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Add Domain
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box display="flex" flexDirection={'column'} gap={2}>
          <Typography variant="body1" gutterBottom>
            Please fill in the details to add a new domain.
          </Typography>
          <TextField
            size="small"
            onChange={onChange}
            name="domainName"
            label="Domain Name"
            value={values.domainName}
            fullWidth
          />
          <TextField
            size="small"
            onChange={onChange}
            name="domainLink"
            label="Domain Link"
            value={values.domainLink}
            fullWidth
          />
          <TextField
            size="small"
            onChange={onChange}
            name="isEntry"
            label="Is Entry"
            select
            value={values.isEntry ? 1 : 0}
            InputLabelProps={{ shrink: true }}
            fullWidth
          >
            <MenuItem value={0}>No</MenuItem>
            <MenuItem value={1}>Yes</MenuItem>
          </TextField>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCancel} variant="contained" color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddDomain;
