import React, { useState, useContext, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  CssBaseline,
  Breadcrumbs,
  Link,
  SvgIcon,
  Divider,
  Tooltip,
} from '@mui/material';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AuthContext } from './context/authContext';
import InboxApp from './components/Inbox';

import {
  Dashboard as DashboardIcon,
  Group as GroupIcon,
  PostAdd as PostAddIcon,
  Domain as DomainIcon,
  Traffic as TrafficIcon,
  Store as StoreIcon,
  Person as AffiliateIcon,
  Category as ProductIcon,
  Public as SovrnIcon,
  Assessment as ReportIcon,
  Security as SecurityIcon,
  Assignment as RequestIcon,
  Campaign as CampaignIcon,
  Logout,
  MenuBook,
} from '@mui/icons-material';

import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './index.css';

const menuItems = [
  { label: 'Offer Group', path: '/offer-group', icon: <DashboardIcon /> },
  { label: 'Logs', path: '/logs', icon: <GroupIcon /> },
  { label: 'Postback', path: '/postback', icon: <PostAddIcon /> },
  { label: 'Offers', path: '/offers', icon: <DashboardIcon /> },
  { label: 'Domains', path: '/domains', icon: <DomainIcon /> },
  { label: 'Publisher', path: '/publishers', icon: <MenuBook /> },
  { label: 'Traffic Sources', path: '/traffic', icon: <TrafficIcon /> },
  { label: 'Merchants', path: '/merchants', icon: <StoreIcon /> },
  { label: 'Affiliates', path: '/affiliates', icon: <AffiliateIcon /> },
  { label: 'Products', path: '/products', icon: <ProductIcon /> },
  { label: 'Sovrn', path: '/sovrn', icon: <SovrnIcon /> },
  { label: 'Hyprmotion', path: '/hp-reports', icon: <ReportIcon /> },
  { label: 'Iron Dome', path: '/iron-dome', icon: <SecurityIcon /> },
  { label: 'Request', path: '/request', icon: <RequestIcon /> },
  { label: 'Cron Jobs', path: '/cron-jobs', icon: <AccessAlarmIcon /> },
  // { label: 'Campaigns', path: '/campaigns', icon: <CampaignIcon /> },
];

function App() {
  const { logout, isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const noLayoutRoutes = ['/login', '/404'];
  const showLayout = !noLayoutRoutes.includes(location.pathname);

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const currentRoute = menuItems.find(
    (item) => item.path === location.pathname
  );
  const currentRouteLabel = currentRoute?.label || 'Admin Panel';

  useEffect(() => {
    document.title =
      currentRouteLabel == 'Admin Panel'
        ? 'Admin Panel'
        : `${currentRouteLabel} | Admin Panel`;
  }, [currentRouteLabel]);

  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <>
      <CssBaseline />

      {showLayout && (
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Admin Panel
            </Typography>
            {isAuthenticated && (
              <IconButton
                sx={{
                  bgcolor: 'white',
                  pl: 0.6,
                  pr: 0.9,
                  pb: 1,
                  pt: 0.5,
                  '&:hover': { bgcolor: 'white' },
                }}
              >
                <InboxApp />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      )}

      {showLayout && (
        <Drawer
          variant="permanent"
          open={isDrawerOpen}
          sx={{
            width: isDrawerOpen ? 240 : 60,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: isDrawerOpen ? 240 : 60,
              boxSizing: 'border-box',
              transition: 'width 0.3s',
              overflow: 'hidden',
            },
          }}
        >
          <Toolbar />
          <Box sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
            <List>
              {menuItems.map((item) => (
                <ListItem
                  key={item.path}
                  component={NavLink}
                  to={item.path}
                  sx={{
                    '&.active': { backgroundColor: '#2074d4', color: 'white' },
                  }}
                >
                  <Tooltip
                    title={!isDrawerOpen ? item.label : ''}
                    placement="right"
                    arrow
                    disableHoverListener={isDrawerOpen}
                  >
                    <ListItemIcon
                      sx={{
                        color: 'inherit',
                        '&.MuiSvgIcon-root': { color: 'white' },
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                  </Tooltip>
                  {isDrawerOpen && (
                    <ListItemText
                      primary={item.label}
                      sx={{ '& .MuiTypography-root': { fontSize: '0.85rem' } }}
                    />
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <List>
              <ListItem
                onClick={logout}
                sx={{
                  '&:hover': { backgroundColor: '#f0f0f0' },
                }}
              >
                <Tooltip
                  title={!isDrawerOpen ? 'Log out' : ''}
                  placement="right"
                  arrow
                  disableHoverListener={isDrawerOpen}
                >
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                </Tooltip>
                {isDrawerOpen && (
                  <ListItemText
                    primary="Log out"
                    sx={{ '& .MuiTypography-root': { fontSize: '0.85rem' } }}
                  />
                )}
              </ListItem>
            </List>
          </Box>
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: showLayout ? (isDrawerOpen ? '240px' : '60px') : '0px',
          marginTop: showLayout ? '64px' : '0px',
          transition: 'margin-left 0.3s',
        }}
      >
        {showLayout && (
          <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
              <Link
                underline="hover"
                color="inherit"
                component={NavLink}
                to="/"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <DashboardIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Home
              </Link>
              {pathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                const menuItem = menuItems.find((item) => item.path === to);
                const displayValue = value === 'block' ? 'block rules' : value;
                return index === pathnames.length - 1 ? (
                  <Typography
                    key={to}
                    color="text.primary"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {menuItem?.icon && (
                      <SvgIcon fontSize="inherit" sx={{ mr: 0.5 }}>
                        {menuItem.icon}
                      </SvgIcon>
                    )}
                    {menuItem?.label || displayValue}
                  </Typography>
                ) : (
                  <Link
                    key={to}
                    underline="hover"
                    color="inherit"
                    component={NavLink}
                    to={to}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {menuItem?.icon && (
                      <SvgIcon fontSize="inherit" sx={{ mr: 0.5 }}>
                        {menuItem.icon}
                      </SvgIcon>
                    )}
                    {menuItem?.label || displayValue}
                  </Link>
                );
              })}
            </Breadcrumbs>
          </>
        )}
        <Outlet />
      </Box>
    </>
  );
}

export default App;
