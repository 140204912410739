import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { DataGrid, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { Range } from 'react-date-range';
import { Add } from '@mui/icons-material';
import CampaignDialog from '../CampaignDialog';

function CampaignTable() {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [newCampaignName, setNewCampaignName] = useState('');

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => setIsDialogOpen(true);
  const handleDialogClose = () => setIsDialogOpen(false);

  const handleSaveCampaign = (data: any) => {
    console.log('Campaign Data Saved:', data);
  };

  const handleDateFilterSubmit = (newDateRange: Range) => {
    setDateRange(newDateRange);
    setLoading(true);
    setPaginationModel({ ...paginationModel, page: 0 });
    fetchData(
      0,
      paginationModel.pageSize,
      newDateRange.startDate,
      newDateRange.endDate,
      sortModel
    );
  };

  const fetchData = async (
    page: number,
    pageSize: number,
    startDate: Date | undefined,
    endDate: Date | undefined,
    sortModel: GridSortModel
  ) => {
    setLoading(true);
    try {
      const startDateFinal = startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD');
      const endDateFinal = endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD');

      const sortColumn = sortModel[0]?.field ?? 'createdAt';
      const sortDirection = sortModel[0]?.sort ?? 'asc';
    } catch (error) {
      console.log('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
    fetchData(
      model.page,
      model.pageSize,
      dateRange.startDate,
      dateRange.endDate,
      sortModel
    );
  };

  const handleSortChange = (model: GridSortModel) => {
    setSortModel(model);
    fetchData(
      paginationModel.page,
      paginationModel.pageSize,
      dateRange.startDate,
      dateRange.endDate,
      model
    );
  };

  useEffect(() => {
    fetchData(
      0,
      paginationModel.pageSize,
      dateRange.startDate,
      dateRange.endDate,
      sortModel
    );
  }, []);

  const tableCols = [
    {
      headerName: 'ID',
      field: '_id',
      flex: 1,
    },
    {
      headerName: 'Date',
      field: 'createdAt',
      flex: 1,
      valueGetter: (params: any) => {
        return new Date(params.row.createdAt)
          .toISOString()
          .slice(0, 16)
          .replace('T', ' ');
      },
    },
    {
      headerName: 'IP',
      field: 'request.ip',
      flex: 1,
      valueGetter: (params: any) => params.row.request?.ip ?? 'N/A',
    },
    {
      headerName: 'User Agent',
      field: 'request.userAgent',
      flex: 2,
      valueGetter: (params: any) => {
        return params.row?.request?.userAgent || 'N/A';
      },
    },
    {
      headerName: 'Location',
      field: 'headers.geoip_city_name',
      flex: 2,
      valueGetter: (params: any) => {
        if (!params.row.headers) return 'N/A';

        const city = params.row.headers.geoip_city_name ?? '';
        const state = params.row.headers.geoip_state_code ?? '';
        const country = params.row.headers.geoip_country_code ?? '';

        const location = [city, state, country].filter(Boolean).join(', ');

        return location || 'N/A';
      },
    },
    {
      headerName: 'Is Blocked',
      field: 'isBlocked',
      flex: 1,
      renderCell: (params: any) => {
        const isBlocked = params.value;

        return (
          <Chip
            label={isBlocked ? 'Blocked' : 'Not Blocked'}
            color={isBlocked ? 'error' : 'success'}
          />
        );
      },
    },
    {
      headerName: 'Block Reason',
      field: 'blockingRules',
      flex: 2,
    },
  ];

  return (
    <Box mt={4}>
      <Box mb={2} display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Chip
            variant="outlined"
            color="primary"
            label={`Starts From: ${moment(dateRange.startDate).format(
              'YYYY-MM-DD'
            )}`}
          ></Chip>
          <Chip
            variant="outlined"
            color="primary"
            label={`Ends To: ${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
          ></Chip>
        </Box>
        <Box>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleDialogOpen}
          >
            Add Campaign
          </Button>
        </Box>
      </Box>
      <DataGrid
        autoHeight
        rows={data}
        columns={tableCols}
        getRowId={(row) => row._id}
        loading={loading}
        paginationMode="server"
        sortingMode="server"
        rowCount={totalRowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        sortModel={sortModel}
        onSortModelChange={handleSortChange}
        pageSizeOptions={[5, 10, 25, 100]}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        getRowHeight={() => 'auto'}
        slots={{}}
      />
      <CampaignDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onSave={handleSaveCampaign}
      />
    </Box>
  );
}

export default CampaignTable;
