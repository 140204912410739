import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GeneralSettings from '../GeneralSettings';
import Offers from '../Offers';

interface Offer {
  id: number;
  label: string;
  weight: number;
  offerId: string | null; // Ensure this matches the Offer interface in Offers component
}

interface CampaignData {
  name: string;
  trafficChannel: string;
  domain: string;
  costType: string;
  value: string;
  offers: Offer[];
}

interface CampaignDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: CampaignData) => void;
}

const CampaignDialog: React.FC<CampaignDialogProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const [campaignData, setCampaignData] = useState<CampaignData>({
    name: '',
    trafficChannel: '',
    domain: '',
    costType: '',
    value: '',
    offers: [
      {
        id: 1,
        label: 'Offer #1',
        weight: 100,
        offerId: null, // Initialize with null
      },
    ],
  });

  const handleSave = () => {
    onSave(campaignData);
    onClose();
  };

  const handleGeneralSettingsChange = (field: string, value: any) => {
    setCampaignData({ ...campaignData, [field]: value });
  };

  const handleOffersChange = (updatedOffers: Offer[]) => {
    setCampaignData({ ...campaignData, offers: updatedOffers });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Add Campaign
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box>
          <Grid container spacing={2} alignItems="flex-start">
            {/* General Settings Section */}
            <Grid item xs={12}>
              <Box>
                <Typography variant="h6" gutterBottom fontSize={16}>
                  General Settings
                </Typography>
                <Divider />
                <GeneralSettings
                  data={{
                    name: campaignData.name,
                    trafficChannel: campaignData.trafficChannel,
                    domain: campaignData.domain,
                    costType: campaignData.costType,
                    value: campaignData.value,
                  }}
                  onChange={handleGeneralSettingsChange}
                  size="small"
                />
              </Box>
            </Grid>
            {/* Offers Section */}
            <Grid item xs={12}>
              <Box mb={4}>
                <Typography variant="h6" gutterBottom fontSize={16}>
                  Offers
                </Typography>
                <Divider />
                <Offers
                  offers={campaignData.offers}
                  onChange={handleOffersChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignDialog;
